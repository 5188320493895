/*
 * @Author: dongjia
 * @Date: 2021-08-16 15:08:21
 * @LastEditTime: 2022-04-21 16:20:52
 * @LastEditors: aleaner
 * @Description: 帖子列表接口
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\api\post-admin\list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

// 获取帖子列表
export const postList = data => {
  return api({
    url: "/admin/circle/post/index",
    method: "post",
    data
  });
};
// 获取活动详情绑定的帖子列表
export const activityPostList = data => {
  return api({
    url: "/admin/cyc_activity/activity/circleTopic",
    method: "post",
    data
  });
};

// 修改贴子精华状态
export const updateRecommend = data => {
  return api({
    url: "/admin/circle/post/changeRecommend",
    method: "post",
    data
  });
};

// 修改帖子推荐状态
export const updateSuggest = data => {
  return api({
    url: "/admin/circle/post/changeSuggest",
    method: "post",
    data
  });
};

// 修改贴子显示状态
export const updateShow = data => {
  return api({
    url: "/admin/circle/post/changeShow",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/circle/post/delete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/circle/post/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/circle/post/putBack",
    method: "post",
    data
  });
};

// 拉黑用户
export const updateBlack = data => {
  return api({
    url: "/admin/circle/post/changeBlack",
    method: "post",
    data
  });
};

// 审核通过
export const check = data => {
  return api({
    url: "/admin/circle/post/check",
    method: "post",
    data
  });
};

// 审核不通过
export const uncheck = data => {
  return api({
    url: "/admin/circle/post/uncheck",
    method: "post",
    data
  });
};

// 获取帖子选项列表
export const postOptions = data => {
  return api({
    url: "/admin/circle/post/all",
    method: "post",
    data
  });
};

// 设置帖子置顶时间
export const setTop = data => {
  return api({
    url: "/admin/circle/post/top",
    method: "post",
    data
  });
};

// 取消置顶设置
export const cancelTop = data => {
  return api({
    url: "/admin/circle/post/cancelTop",
    method: "post",
    data
  });
};