<!--
 * @Author: dongjia
 * @Date: 2021-08-23 14:48:42
 * @LastEditTime: 2021-08-23 15:33:34
 * @LastEditors: aleaner
 * @Description: 评论回复弹窗
 * @FilePath: \saas-admin-vue\src\modules\community-admin\components\postAdmin\ReplyDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="commentType === 'comment'?'评论':'回复'" :visible.sync="openDialog" width="500px"
    :show-close="true" class="dialog-vertical" @close="cancel('reply')">
    <el-form size="medium" :model="replyData" :rules="rules" ref="reply" label-width="80px">
      <el-form-item :label="`${commentType === 'comment'?'评论':'回复'}内容：`" prop="content">
        <el-input type="textarea" :rows="6" v-model="replyData.content"
          :placeholder="`请输入${commentType === 'comment'?'评论':'回复'}内容`"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('reply')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { commentPost, replyComment } from "../../api/post-admin/detail";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    commentType: {
      type: String,
      default: "comment",
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      optionsLoading: false,
      rules: {
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          if (this.commentType === "comment") {
            commentPost(this.replyData)
              .then((res) => {
                this.$message.success(res.msg);
                this.updateList();
                this.$refs[formName].resetFields();
                this.openDialog = false;
                this.saveLoading = false;
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          } else {
            replyComment(this.replyData)
              .then((res) => {
                this.$message.success(res.msg);
                this.updateList();
                this.$refs[formName].resetFields();
                this.openDialog = false;
                this.saveLoading = false;
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    replyData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>