<!--
 * @Author: dongjia
 * @Date: 2021-08-13 18:15:23
 * @LastEditTime: 2022-03-28 16:15:18
 * @LastEditors: aleaner
 * @Description: 不通过审核弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\community-admin\components\postAdmin\NoCheckedDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="不通过审核" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('noCheck')">
    <el-form class="medium-form" size="medium" :model="noCheckData" :rules="rules" ref="noCheck" label-width="65px">
      <el-form-item label="原因：" prop="reason">
        <el-input type="textarea" :rows="6" :maxlength="20" show-word-limit v-model="noCheckData.reason"
          placeholder="请输入内容" style="min-height: 120px;"></el-input>
        <div class="info">
          <i class="el-icon-info" />
          <span>将通过订阅消息、系统消息发送通知</span>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="openDialog = false">取消</el-button>
      <el-button size="medium" type="primary" @click="submit('noCheck')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { uncheck } from "../../api/post-admin/list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      optionsLoading: false,
      rules: {
        reason: [
          { required: true, message: "请输入不通过原因", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          uncheck(this.noCheckData)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.$refs[formName].resetFields();
              this.openDialog = false;
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    noCheckData: {
      get() {
        return this.Data;
      },
      set(val) {
        this.$emit("update: updateData", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
